import React, { createContext, useContext, useMemo } from "react";
import { IConsentEventData, ITrackingContext } from "./types";
import { jsonParseString } from "../../utils/string";

export const TrackingContext = createContext({} as ITrackingContext);

type Props = {
    children: React.ReactNode;
};

export const consentEvent: ITrackingContext["consentEvent"] = (data) => {
    if (!data) return;

    const gtag = window?.["gtag"];

    gtag && gtag('consent', 'default', data);
}

export const identifyEvent: ITrackingContext["identifyEvent"] = (data) => {
    if (!data) return;

    if (!hasGrantedConsentFor("ad_storage")) return;

    const gtm = window?.["dataLayer"];
    const klaviyo = window?.["_learnq"];

    gtm?.push({
        event: "identify",
        userData: {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
        }
    });

    klaviyo?.push([
        "identify",
        {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
        }
    ]);
}

export const addToCartEvent: ITrackingContext["addToCartEvent"] = (data) => {
    if (!data) return;

    if (!hasGrantedConsentFor("ad_storage")) return;

    const gtm = window?.["dataLayer"];
    const klaviyo = window?.["_learnq"];

    gtm.push({
        event: "add_to_cart",
        ecommerce: {
            value: data.totalAmount,
            currency: data.currencyCode,
            items: data?.orderItems?.filter(oi => !oi.parentId)
                .map(item => {
                    const schema = JSON.parse(item?.schema ?? "{}");
                    let seoData: any = {};
                    if (Array.isArray(schema)) {
                        seoData = schema.find(s => s.salesChannelId === data.salesChannel?.id);
                    } else {
                        seoData = schema || {};
                    }
                    return {
                        item_id: item.code || item.skuId,
                        item_name: item.name,
                        affiliation: data.salesChannel?.name,
                        index: 0,
                        price: item.unitAmount,
                        quantity: item.quantity,
                        item_brand: seoData?.brand
                    };
                })
        }
    });

    const klavAddToCartEvent = data.clientData?.find(cd => cd.type === "klaviyo")?.data;
    if (klavAddToCartEvent) {
        klaviyo.push(["track", "Added to Cart", jsonParseString(klavAddToCartEvent)]);
    }
}

export const addToCartLinkEvent: ITrackingContext["addToCartLinkEvent"] = (data) => {
    if (!data) return;

    if (!hasGrantedConsentFor("ad_storage")) return;

    const gtm = window?.["dataLayer"];

    gtm?.push({
        event: "addToCartLink",
        cartLinkId: data.cartLinkId
    });
}

export const beginCheckoutEvent: ITrackingContext["beginCheckoutEvent"] = (data) => {
    if (!data) return;

    if (!hasGrantedConsentFor("ad_storage")) return;

    const gtm = window?.["dataLayer"];
    const klaviyo = window?.["_learnq"];

    gtm?.push({
        event: "begin_checkout",
        ecommerce: {
            value: data.totalAmount,
            currency: data.currencyCode,
            coupon: (data?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
            items: data.orderItems?.filter(oi => !oi.parentId).map(item => {
                const schema = JSON.parse(item?.schema ?? "{}");
                let seoData: any = {};
                if (Array.isArray(schema)) {
                    seoData = schema.find(
                        s => s.salesChannelId === data.salesChannel?.id
                    );
                } else {
                    seoData = schema || {};
                }

                return {
                    item_id: item.code || item.skuId,
                    item_name: item.name,
                    affiliation: data.salesChannel?.name,
                    index: 0,
                    price: item.unitAmount,
                    quantity: item.quantity,
                    item_brand: seoData?.brand
                };
            })
        }
    });

    const unixtimestamp = Math.floor(Date.now() / 1000);
    const orderItems = data.orderItems?.filter(oi => !oi.parentId) || [];
    if (orderItems.length < 1) return;

    klaviyo?.push([
        "track",
        "Started Checkout",
        {
            $event_id: data.id + "_" + unixtimestamp,
            $value: data.totalAmount,
            CheckoutURL: window.location.href,
            ItemNames: orderItems.map(item => item.name),
            Items: orderItems.map(item => {
                const file = JSON.parse(item.imageUrl);
                const imageUrl = file?.url || "";

                return {
                    ProductID: item.skuId,
                    ProductName: item.name,
                    SKU: item.code,
                    ImageURL: imageUrl,
                    ProductURL: item.productUrl,
                    ItemPrice: item.unitAmount,
                    Quantity: item.quantity,
                    RowTotal: item.totalAmount
                };
            })
        }
    ]);
}

export const purchaseEvent: ITrackingContext["purchaseEvent"] = (data) => {
    if (!data) return;

    if (!hasGrantedConsentFor("ad_storage")) return;

    const gtm = window?.["dataLayer"];

    gtm?.push({
        event: "purchase",
        ecommerce: {
            transaction_id: data.number,
            value: data.totalAmount,
            tax: data.totalTax,
            shipping: data.shippingAmount,
            currency: data.currencyCode,
            coupon: (data?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
            items: data.orderItems?.filter(oi => !oi.parentId).map(item => {
                const schema = JSON.parse(item?.schema ?? "{}");
                let seoData: any = {};
                if (Array.isArray(schema)) {
                    seoData = schema.find(s => s.salesChannelId === data.salesChannel?.id);
                } else {
                    seoData = schema || {};
                }
                let itemDiscount;
                if (data.discountAmount && data.itemsCount) {
                    itemDiscount = (
                        data.discountAmount / data.itemsCount
                    ).toFixed(2);
                }

                return {
                    item_id: item.code || item.skuId,
                    item_name: item.name,
                    affiliation: data.salesChannel?.name,
                    index: 0,
                    coupon: (data?.couponsUsed ?? [])
                        ?.map(el => el.couponCode)
                        .join(","),
                    discount: parseFloat(itemDiscount || 0),
                    price: item.unitAmount,
                    quantity: item.quantity,
                    item_brand: seoData?.brand
                };
            })
        }
    });
}

export const viewedProductEvent: ITrackingContext["viewedProductEvent"] = (data) => {
    if (!data) return;

    if (!hasGrantedConsentFor("ad_storage")) return;

    const klaviyo = window?.["_learnq"];
    const gtag = window?.["gtag"];

    gtag && gtag("event", "view_item", {
        value: data.currentPrice,
        currency: data.currencyCode,
        items: [
            {
                item_id: data.SKUCode,
                item_name: data.SKUName,
                item_brand: jsonParseString(data.schema)?.brand,
                price: data.currentPrice,
            }
        ]
    });

    klaviyo?.push(["track", "Viewed Product", {
        ProductName: data.SKUName,
        ProductID: data.id,
        SKU: data.SKUCode,
        Categories: [],
        ImageURL: data.imageUrl,
        URL: data.url,
        Brand: jsonParseString(data.schema)?.brand,
        Price: data.currentPrice,
        CompareAtPrice: data.listPrice
    }]);
}

const getCookie = (name: string) => {
    var match = document?.cookie?.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return undefined;
}

const hasGrantedConsentFor = (key: keyof IConsentEventData) => {
    const cookie = getCookie("cyclicCookie");
    if (!cookie) return false;
    const decoded = decodeURIComponent(cookie);
    const consent = jsonParseString(decoded) as (IConsentEventData | null);
    return consent?.[key] === "granted";
}

export const TrackingProvider = ({ children }: Props) => {
    const memoizedValue = useMemo(
        () =>
        ({
            identifyEvent,
            addToCartEvent,
            addToCartLinkEvent,
            beginCheckoutEvent,
            purchaseEvent,
            consentEvent,
            viewedProductEvent
        } as ITrackingContext),
        [
            identifyEvent,
            addToCartEvent,
            addToCartLinkEvent,
            beginCheckoutEvent,
            purchaseEvent,
            consentEvent,
            viewedProductEvent
        ]
    );

    return (
        <TrackingContext.Provider value={memoizedValue}>
            {children}
        </TrackingContext.Provider>
    );
}

export const useTrackingContext = () => {
    const context = useContext(TrackingContext);
    if (!context) {
        console.error("useTrackingContext context must be used inside TrackingContext.Provider");
        throw new Error("useTrackingContext context must be used inside TrackingContext.Provider");
    }
    return context;
};